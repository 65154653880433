
    

@media(max-width:1700px){
    .ourProduct{
        padding: 50px 0px;
    }
    .video-outer{
        background-size: cover;
    }
}
@media(max-width:1400px){
    .paymentoption .btn-primary{
        min-width: auto;
    }
    .paymentoption .text-center{
        padding-left: 21px;
    }
    .graphOuter{
        width: 720px;
    }
    .launch-heading{
        left: 120px;
    }
    .bottomtabs .nav-tabs li.nav-item button{
        font-size: 16px;
    }
    .cartprofile-outer {
        margin: 30px 30px 0px 15px;
    }
    .swiper-button-prev {
        left: calc(50% - 157px) !important;
    }
    .swiper-button-next {
        right: calc(50% - 157px) !important;
    }
    .navbar-expand-lg .navbar-nav .nav-link{
        padding: 10px 10px;
    }
    .video-content h4{
        font-size: 32px;
    }
    .product-outer::after{
        right: -24px;
        transform: rotate(57deg);
    }
    .product-outer::before{
        left: -24px;
        transform: rotate(-57deg);
    }
    .product-outer a::after{
        right: -25px;
        transform: rotate(-57deg);
    }
    .product-outer a::before{
        bottom: 0px;
        left: -25px;
        transform: rotate(57deg);
    }
    .video-headings p{
        font-size: 18px;
    }
    .advancedShaft{
        background-size: cover;
        background-position: center;
    }
    .productInner a h5{
        font-size: 26px;
    }
    .sidebar::before{
        top: 9px;
        left: -14px;
        transform: rotate(-63deg);
    }
    .sidebar::after{
        top: 7px;
        right: -13px;
        transform: rotate(61deg);
    }
    .sidebar h3::before{
        bottom: 8px;
        left: -14px;
        transform: rotate(62deg);
    }
    .sidebar h3::after{
        right: -14px;
        transform: rotate(-62deg);
    }
    .mainOuter::before{
        left: -14px;
        transform: rotate(-65deg);
    }
    .mainOuter::after{
        right: -13px;
        transform: rotate(64deg);
    }
    .mainOuter .ItemImgOuter::after{
        left: -13px;
        transform: rotate(63deg);
    }
    .mainOuter .ItemImgOuter::before{
        right: -13px;
        transform: rotate(-64deg);
    }
    .pageheading .headingContent{
        padding: 20px 0px;
    }
    .sidebar h3{
        padding: 15px 10px;
        font-size: 30px;
    }
    ul.categoryList li a{
        padding: 10px;
    }
    .productName img{
        width: 40px;
        height: 40px;
    }
    .productName h4{
        font-size: 18px;
    }
    span.counter{
        width: 30px;
        height: 30px;
        font-size: 18px;
    }
    .itemContentOuter h4 a{
        font-size: 22px;
    }
    .itemContentOuter h5{
        font-size: 24px;
    }
    .itemContentOuter p{
        font-size: 16px;
        margin: 12px 0px 20px;
    }
    .itemContentOuter>span{
        font-size: 16px;
    }
    .itemContentOuter h5 sup {
        top: -12px;
        left: -1px;
    }
    .list-unstyled.multi-steps li::before {
        width: 12px;
        left: -7px;
    }
    .list-unstyled.multi-steps li svg{left: -5px;}
    .navbar-expand-lg .navbar-nav>a, .navbar-expand-lg .navbar-nav>li>a, .navbar-expand-lg .navbar-nav .dropdown>a {
        padding: 40px 10px !important;
    }
    .sliderPart button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
        right: calc(4% + 1px);
    }
    .sliderPart button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
        left: calc(4% + 1px);
    }
}
@media(max-width:1199.98px){
    .kinetixx-golf h4{
        margin: -8px 0 0px;
    }
    .imageTab .img-fluid{
        object-position: -380px;
    }
    .paymentoption .btn-primary{
        min-width: 160px;
    }
    .paymentoption .text-center {
        padding-left: 41px;
    }
    ul.cat-additional-pModel .cart-partright{
        min-width: 55px;
    }
    .iron-content .metricChange .changeMetirc{
        right: 50px;
    }
    .graphOuter{
        width: auto;
        margin: 0px;
    }
    .graphpanel .sidebar-outer{
        width: 70%;
    }
    .launch-heading {
        left: 170px;
    }
    .H-label {
        left: initial;
        right: 700px;
    }
    .strecher{
        justify-content: flex-end;
        margin-left: auto !important;
        margin-right: 0px !important;
    }
    .strecher, .V-label{
        width: 680px;
        margin-left: auto;
    }
    .V-label{
        margin-left: auto;
    }
    .graphpanel .container {
        max-width: 100%;
    }
    .social-icon{
        justify-content: center;
    }
    .newsletter{
        padding: 50px 0px;
    }
    .video-headings p, .product-heading p, .emailnews.form-group, .newsletter-right p.message{
        width: 100%;
    }
    .newsImg img{
        width: 250px;
    }
    .newsletter-right{
        padding: 50px 50px 50px 150px;
    }
    .small .paymentoption{
        align-items: flex-start;
    }
    .bottomtabs .nav-tabs li.nav-item button{
        font-size: 14px;
    }
    .cartprofile-outer {
        margin: 10px 30px 0px 15px;
    }
    .list-unstyled.multi-steps li::before {
        width: 22px;
        left: -22px;
        top: 10.9px;
        height: 1px;
    }
    .searchProduct .btn.btn-primary {
        width: 60px;
    }
    .list-unstyled.multi-steps li svg {
        left: -12px;
    }
    header .container {
        max-width: 100%;
    }
    .slider-content{
        width: 450px;
        padding: 25px 25px;
    }
    .slider-content h1, .video-headings h2 .innerpages p, .video-headings h2, .product-heading h2, .product-outer a h3, .iron-content h4{
        font-size: 30px;
    }
    .product-outer a h3 img {
        height: 32px;
    }
    .product-outer a h3 span.d-block{
        font-size: 27px;
    }
    .product-outer p{
        font-size: 20px;
    }
    .slider-content h4{
        font-size: 20px;
    }
    .slider-content p{
        font-size: 15px;
        line-height: 25px;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 10px 6px;
        font-size: 14px;
    }
    .btn-primary{
        padding: 10px 10px;
        font-size: 13px
    }
    .video-headings h3{
        font-size: 25px;
    }
    .video-headings p, .product-heading p {
        font-size: 15px;
        line-height: 25px;
    }
    .video-content h4 {
        font-size: 22px;
    }
    .slider-content .btn-primary {
        font-size: 16px;
    }
    .advancements .slider-content h3{
        margin: 5px 0px;
    }
    .product-heading h3{
        margin: 8px 0px;
        font-size: 22px;
    }
    .exclusiveUpdates, .product-selector, .video-outer{
        padding: 50px 0px;
    }
    .product-outer::after {
        right: -26px;
        transform: rotate(56deg);
    }
    .product-outer::before {
        left: -26px;
        transform: rotate(-56deg);
    }
    .product-outer a::after {
        right: -26px;
        transform: rotate(-56deg);
    }
    .product-outer a::before {
        left: -26px;
        transform: rotate(56deg);
    }
    .shaftPlayer img {
        width: 72%;
    }
    button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
        left: 32%;
    }
    button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
        right: 32%;
    }
    .superiordown{
        width: 430px;
    }
    .superiour-left {
        min-width: 180px;
        max-width: 180px;
    }
    .superiour-right h1, .productdetails-right h1 a, .paymentoption h5{
        font-size: 30px;
    }
    .superiour-right h4, .productdetails-right h4{
        font-size: 22px;
    }
    .alltypes p{
        font-size: 18px;
    }
    .detailheading, .custom_check{
        font-size: 16px;
    }
    .custom_check a{
        font-size: 11px;
    }
    ul.reviewlist{
        width: 100%;
    }
   
    .checkoutpage h2{
        font-size: 30px;
    }
    .checkoutDetail a h5{
        font-size: 24px;
    }
    .additionalProduct h4, .coupancodeouter h4{
        font-size: 18px;
    }
    .additinalone{
        width: calc(100%);
    }
    .checkimg, .addproductimg{
        width: 100px;
    }
    .addcontent{
        width: calc(100% - 100px);
    }
    .navbar-expand-lg .navbar-nav .nav-link{
        white-space: nowrap;
    }
    .navbar-brand img {
        width: 180px;
    }
    .submenuList{
        flex-wrap: wrap;
    }
    .list-unstyled.multi-steps li{
        font-size: 14px;
    }
    .list-unstyled.multi-steps{
        width: 65%;
    }
    span.spetImg{
        font-size: 40px;
    }
    .accountInnner h2{
        font-size: 28px;
    }
    .accountInnner {
        padding: 30px 23px 10px;
    }
    .accountseprate a{
        height: 220px;
    }
    .inquiries-list ul li a{
        word-wrap: break-word;
    }
    .officeaddress{
        padding: 20px;
        width: 80%;
    }
    .mediapanel {
        max-width: 250px;
        width: 250px;
    }
    .media-content {
        width: calc(100% - 250px);
    }
    .selector-tools-body{
        width: 80%;
    }
    .facility-bg{
        margin-bottom: 20px;
        min-height: initial;
    }
    .detailTBOuter tbody tr th {
        vertical-align: middle;
        background: #e7e5df52;
        color: #435568;
        font-weight: 600;
        width: 300px;
    }
    .loginOuter button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
        left: 0px;
    }
    .loginOuter button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
        right: 0px;
    }
}

@media(max-width:991.98px){
    .imageTab .img-fluid{
        object-position: -270px;
        height: 530px;
    }
    .H-label label{
        min-height: 130px;
    }
    ul.cat-additional-pModel li {
        flex-wrap: nowrap;
    }
    
    .sliders .metricChange .changeMetirc {
        position: initial;
        text-align: center;
        margin-bottom: 12px;
    }
    .metricChange span.custom_check {
        font-size: 11px !important;
    }
    .metricChange .changeMetirc{
        top: 15px;
    }
    .iron-content .metricChange .changeMetirc{
        right: 100px;
    }
    .graphpanel .sidebar-outer{
        width: 240px;
        margin: 0px auto;
    }
    .graphOuter{
        width: auto;
    }
    .V-label {
        width: 600px;
        margin-left: auto;
    }
    .H-label {
        left: initial;
        right: 620px;
        height: 650px;
    }
    .launch-heading {
        left: initial;
        right: 250px;
    }
    .strecher {
        width: 600px;
        margin-right: 0px !important;
        justify-content: flex-end;
        margin-left: auto;
        height: 650px;
    }
    .divaider-2{
        height: 130px;
        min-height: 130px;
    }
    .cart-bottom-buttons button.btn.btn-secondary{
        top: 0px;
    }
    .checkoutOuter {
        margin-bottom: 0px;
    }
    .detailTBOuter tbody tr th{
        width: 210px;
    }
    .newsletter-main{
        flex-direction: column;
    }
    .newsImg img{
        left: 0px;
        width: 50%;
    }
    .newsImg {
        text-align: center;
    }
    .newsletter-right{
        padding: 100px 50px 50px;
        margin-top: -80px;
    }
    .newsletter{
        background-size: cover;
        background-position: center center;
    }
    .paymentoption{
        flex-wrap: wrap;
    }
    .paymentoption .text-center{
        text-align: left !important;
    }
    .player-image{
        margin-left: -553px;
    }
    .application .sliders{
        width: 346px;
    }
    .application .sliders .border-box{
        padding: 20px 20px;
    }
    .application .player-content{
        left: 40px;
    }
    body{
        padding-top: 92px;
    }
    
    .bottomtabs .nav-tabs li.nav-item{
        width: auto;
    }
    .bottomtabs .nav-tabs li.nav-item button{
        padding: 5px 15px;
        width: 100%;
    }
    
    .detailTBOuter table.w-100.table.table-bordered tbody tr td{
        vertical-align: middle;
    }
    
    .Innerbanner{
        height: 220px;
    }
    .technology-mat-content.patent-count {
        padding-left: 35px;
    }
    .patents-outer .align-items-center.row {
        align-items: flex-start !important;
    }
    .technology-mat-content{
        padding-left: 0px;
    }
    .compareOuter.technology  h4 {
        font-size: 14px;
    }
    .accountInnner p{
        margin: 0px;
        display: flex;
        align-items: center;
    }
    .store-banner{
        background-size: cover;
    }
    .list-unstyled.multi-steps {
        width: 100%;
    }
    .list-unstyled.multi-steps li svg {
        left: -20px;
    }
    .list-unstyled.multi-steps li::before {
        width: 26px;
        left: -37px;
    }    
    .checkoutRight{margin-top: 30px; min-height: initial;}
    
    .compare-head .compare-leftpart, .compare-head .compare-center, .compare-head .compare-right{
        font-size: 16px;
        word-wrap: break-word;
    }
    .compareInner h2, .compareOuterpanel .compareTd{
        font-size: 14px;
    }
    .compareOuter .headcenter h2{
        font-size: 30px;
    }
    .compareOuter h4{
        font-size: 24px;
    }
    .enter-profile{
        padding: 0px;
    }
    header .navbar-expand-lg .navbar-nav>a, 
    header .navbar-expand-lg .navbar-nav>li>a, 
    header .navbar-expand-lg .navbar-nav .dropdown>a {
        padding: 10px 10px !important;
    }
    .submenuList{flex-wrap: wrap;}
    .submenuList>li {
        width: 50%;
    }
    footer header .navbar-expand-lg .navbar-nav .nav-link {
        padding: 10px 3px !important;
    }
    footer .navbar>.container {
        flex-wrap: wrap !important;
    }
    footer .btn-primary {
        font-size: 14px;
        position: absolute;
        right: 8px;
        top: 60px;
    }
    .slick-slide.slick-active {
        position: relative;
        top: 45px;
    }
    .headtwo h5 {
        margin-left: 3px;
        width: calc(100% - 6px);
    }
    a.continuebtn{
        font-size: 15px
    }
    
    button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
        left: 31%;
    }
    button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
        right: 31%;
    }
    .shaftPlayer img {
        width: 72%;
    }
    .navbar-expand-lg .navbar-nav .nav-link{
        padding: 10px 15px;
    }
    
    button.navbar-toggler {
        box-shadow: none !important;
        position: absolute;
        right: 18px;
        top: 2px;
        padding: 8px 13px;
        border-radius: 0px;
        border: 2px solid #6d2a5f;
        color: #6d2a5f;
    }
    button.navbar-toggler:hover {
        background: #6d2a5f;
        color: #fff;
    }
    
    nav.w-100.p-0.navbar.navbar-expand-lg.navbar-light {
        padding-right: 75px !important;
    }
    
    .navbar-collapse {
        position: absolute;
        top: 60px;
        background: #fffef5;
        z-index: 1000;
        width: calc(100%  - 30px);
        left: 15px;
        border-radius: 0px 0px 10px 10px;
    }
    .cartprofile-outer {
        margin: 0px;
        position: absolute;
        right: 196px;
        z-index: 1000;
    }
    .slider-content {
        width: 380px;
        padding: 20px 20px;
    }
    .product-outer p{
        font-size: 16px;
    }
    .product-outer::before {
        left: -27px;
        transform: rotate(63deg);
    }
    .product-outer::before {
        left: -27px;
        transform: rotate(-63deg);
    }
    .product-outer a::after{
        right: -27px;
        transform: rotate(-63deg);
    }
    .product-outer a::before{
        left: -27px;
        transform: rotate(63deg);
    }
    .slider-content h1, .video-headings h2 .innerpages p, .other-match h4, .video-headings h2, .product-heading h2, .product-outer a h3, .technology-mat-content h2, .iron-content h4 {
        font-size: 28px;
    }
    .product-outer a h3 img {
        height: 27px;
    }
    .product-outer a h3 span.d-block {
        font-size: 24px;
    }
    section.compareOuter.technology .headcenter div {
        padding: 0px 20px;
    }
    
    .technology-mat-content p{
        width: 100%;
    }
    .technology-tabs .align-items-center.row, .facility-outer .align-items-center.row {
        align-items: flex-start !important;
    }
    .video-headings p span, .product-heading p span, .slider-content p span{
        display: inline !important;
    }
    .slider-content h4, .technology-mat-content h4 {
        font-size: 20px;
        margin: 8px 0px;
    }
    .video-headings h3 {
        font-size: 22px;
        margin: 10px 0px;
    }
    .video-content h4 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 5px;
    }
    .slider-content .btn-primary {
        font-size: 15px;
        padding: 10px 10px;
    }
    .product-heading h3 {
        margin: 8px 0px;
        font-size: 18px;
    }
    footer button.navbar-toggler.collapsed {
        display: none;
    }
    
    footer nav.w-100.p-0.navbar.navbar-expand-lg.navbar-light {
        padding: 0px !important;
    }
    
    footer .cartprofile-outer {
        right: 0px;
        position: initial;
    }
    
    footer .navbar-collapse {
        display: inline-block !important;
        position: initial;
        width: auto !important;
        flex-basis: auto;
    }
    
    footer .container {
        display: flex;
        flex-wrap: nowrap !important;
    }
    footer .navbar-collapse .navbar-nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    footer .navbar-brand img {
        width: 190px;
    }
    footer .navbar-expand-lg .navbar-nav .nav-link {
        padding: 10px 10px;
        font-size: 13px;
    }
    .cart{
        margin: 0px;
    }
    footer .btn-primary {
        font-size: 14px;
    }
    sup{
        width: 13px;
        height: 13px;
        font-size: 8px;
    }
    .product-heading h2 sup, .video-headings h2 sup {
        top: -18px;
    }
    .product-outer a h3 sup {
        top: -12px;
    }
    .swiper-button-prev {
        left: calc(50% - 147px) !important;
    }
    .swiper-button-next {
        right: calc(50% - 148px) !important;
    }
    .video-outer li.react-multi-carousel-item.react-multi-carousel-item--active {
        left: 0px;
    }
    
    .video-outer li.react-multi-carousel-item.react-multi-carousel-item--active+li.react-multi-carousel-item.react-multi-carousel-item--active {
        left: 0px;
    }
    .videoTab{
        width: calc(100% - 120px);
    }
    .itemList li{
        width: 50%;
    }
    .ItemImgOuter img{
        height: 163px;
    }
    .mainOuter::before {
        left: -14px;
        transform: rotate(-63deg);
    }
    .mainOuter::after {
        right: -14px;
        transform: rotate(64deg);
    }
    .mainOuter .ItemImgOuter::before {
        right: -14px;
        transform: rotate(-64deg);
    }
    .mainOuter .ItemImgOuter::after {
        left: -14px;
        transform: rotate(63deg);
    }
    
    .superiour-right h1, .productdetails-right h1 a, .paymentoption h5{
        font-size: 22px;
    }
    .productdetails-right {
        padding: 10px 0px 0px;
    }
    .superiour-right h4, .productdetails-right h4 {
        font-size: 18px;
    }
    .quantity-tab{
        margin: 0px 15px;
    }
    .superiordown {
        width: 330px;
    }
    .superiour-left {
        min-width: 150px;
        max-width: 150px;
    }
    .superiour-right {
        padding-left: 8px;
    }
    .Quantitybox {
        margin: 20px 0px 60px;
    }
    .detailstabspanel {
        margin-top: -36px;
    }
    .paymentsidebar, .whiteBox{
        padding: 15px;
    }
    ul.cartlist li{
        flex-wrap: wrap;
    }
    .store-banner .headingContent h4{
        width: 100%;
    }
    
}

@media(max-width:767.98px){
    span.clientname {
        top: 35px;
        
    }
    ul.infolist{
        padding: 15px;
    }
    ul.infolist li a{
        margin-left: 10px;
    }
    .react-tooltip{
        white-space: pre-line;
        max-width: 300px;
    }
    .custom_check small.invalid-feedback-text{
		font-size: 11px;
	}
	.cartprofile-outer .cart {
	    padding-right: 9px;
	}
    footer  span.navbar-brand {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .boxtype {
        flex-direction: column;
        margin: 20px auto;
        max-width: 330px;
    }
    
    .boxtype .headingtab {
        margin-bottom: 40px;
    }
    
    .boxtype .headingtab:last-child {
        margin-bottom: 0px;
    }
    .divaider-2:hover .show-mobile span {
        color: #6d2a5f;
    }
    .thankyou-outer h3{
        font-size: 24px;
    }
    span.usercontentdetails{
        top: 27px;
        left: initial;
        right: 0px;
    }
    .thankyou-outer h4, .thankyou-outer h5{
        font-size: 16px;
    }
    .thankyou-outer img{
        width: 120px;
    }
    span.usercontentdetails:before{
        left: 88%;
    }
    .cardbody-left h5, .cardbody-right h5{
        font-size: 16px;
    }
    .cardbody-left p, .cardbody-right p{
        font-size: 14px;
    }
    .accountInnner{
        padding-top: 0px;
    }
    .listbody a{
        font-size: 18px;
    }
    .listbody{
        height: initial;
        margin-bottom: 0px;
    }
    .whiteBox.mb-3{
        height: initial;
    }
    
    .lab-outer label{
        width: 100%;
    }
    .labes-type{
        flex-direction: column;
    }
    .iron-content .metricChange .changeMetirc {
        top: 100px;
        right: 0px;
        left: 57%;
        transform: translateX(-50%);
    }
    p.cart-quantity .quantity-tab .btn.update-btn{
        margin-left: 3px;
    }
    input#quantity{
        width: 73px;
    }
    .more-info .productdetails-right {
        padding: 20px 7px;
    }
    
    .more-info .productdetails-right .row {
        margin: 0px -7px !important;
    }
    .Driver-selecter-outer .product-outer-main {
        margin-bottom: 15px;
    }
    .divaider-2{
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .strecher{
        height: initial;
    }
    .graphOuter{
        margin: 0px;
    }
    .show-mobile {
        background: #fffef5;
        border-bottom: 1px solid #6d2a5f;
        display: block;
        margin-bottom: 25px;
        padding: 8px 0px;
    }
    
    .show-mobile span {
        display: inline-block;
        width: auto;
        color: #435568;
        padding: 0px;
        font-size: 13px !important;
        font-weight: 700 !important;
    }
    .graphpanel .sidebar-outer{
        width: 280px;
    }
    .strecher{
        width: 100%;
    }
    .divaider-2.one-center {
        display: none;
    }
    .divaider-1{
        height: initial;
    }
    .divaider-2 {
        height: initial;
        min-height: initial;
        width: 280px;
        margin: 0px auto 20px;
        background: #fffef5;
        border: 1px solid #6d2a5f;
        padding-bottom: 30px;
    }
    .divaider-2 span{
        font-size: 15px;
        color: #6d2a5f;
    }
    .launch-heading, .H-label, .V-label ,.spin-heading {
        display: none;
    }
    .bottomtabs .nav-tabs{
        justify-content: space-between;
    }
    .bottomtabs .nav-tabs{
        border: 0px;
    }
    
    .newsImg img{
        width: 80%;
    }
    .cart-bottom-buttons{
        padding: 0px;
    }
    .react-multiple-carousel__arrow{
        min-height: 28px;
        min-width: 28px;
    }
    .react-multiple-carousel__arrow:before{
        font-size: 10px;
    }
    .selector-tools .progressBar{
        margin: 10px auto 40px;
    }
    .selector-tools > p{
        width: 100%;
    }
    .selector-tools-body {
        width: 100%;
        margin: 20px 0px 0px;
    }
    .application .player-content{
        display: none;
    }
    .small .productdetails-right {
        padding: 15px 0px 0px !important;
    }
    .application{
        padding: 20px 0px;
        min-height: initial;
    }
    .application .sliders{
        position: initial;
        width: 100%;
        margin-top: 20px;
    }
    .small .paymentoption .addcart{
        margin-left: 0px;
    }
    .application .sliders .border-box {
        padding: 20px 20px 20px;
        width: calc(100% - 0px);
    }
    section.Driver-selecter-outer.facility-outer.pb-0{
        padding-top: 0px !important;
    }
    section.Driver-selecter-outer.facility-outer.pb-0 hr {margin-bottom: 0px !important;}
    section.Driver-selecter-outer.facility-outer.otherProducts{
        padding: 15px 0px;
    }
    .lab-outer{
        margin: 5px;
    }
    .iron-content{
        padding: 30px 10px;
    }
    .lab-outer label{
        font-size: 14px;
        padding: 7px 15px;
    }
    span.clientname{
        top:35px;
    }
    body {
        padding-top: 70px;
    }
    .media-content {
        width: calc(100% - 180px);
    }
    .mediapanel {
        max-width: 180px;
        width: 180px;
    }
    .mediapanel img{
        height: 100px;
    }
    .headOuter{
        margin-bottom: 50px;
    }
    .officeaddress {
        position: initial;
        transform: initial;
    }
    .company-address iframe{
        margin-top: 20px;
    }
    .inquiries-list ul li{
        width: calc(100% / 2 - 10px);
    }
    .bottomtabs .nav-tabs.gears {
        justify-content: space-between;
    }
    
    .bottomtabs .nav-tabs.gears li.nav-item {
        width: 49%;
    }
    .detailTBOuter{
        padding: 20px 15px;
    }
    
    .navbar-collapse{
        top:51px;
        max-height: 460px;
        overflow: auto;
    }
    .dropdownmain{
        flex-wrap: wrap;
    }
    .shaftPart, .storePart {
        width: 100%;
    }
    .technology-mat-content{
        padding: 15px 0px;
    }
    .technology-img {
        margin: 10px 0px;
    }
    .addressList ul li {
        width: calc(100% / 1 - 0px);
    }
    .wishlistmain .cartproductdetails{
        flex-direction: column;
    }
    .cartdetailsleft {
        width: calc(100%);
    }
    .wishlistmain .cartproductdetails{
        padding: 15px;
    }
    .cartproductcontentdetails{
        padding-left: 15px;
    }
    .wishlistmain .cartdetailsright {
        width: 100%;
        margin-top: 30px;
        justify-content: space-between;
        display: flex;
        align-items: center;
    }
    .cartdetailsright a {
        display: inline-block;
    }
    
    .cartdetailsright button.removeitem {
        margin: 0px 5px;
    }
    .cartproductdetailstab .cartproductimg{
        width: 100px;
    }
    .accountseprate{
        width: calc(100% / 2);
    }
    .accountseprate a h4{
        font-size: 16px;
    }
    
    #header{
        align-items: flex-start;
        padding: 10px 0px;
    }
    
    #header .daniel-michael{
        bottom: -58px;
    }
    .accountInnner h2{
        font-size: 30px;
    }
    
    .informations .paymentsidebar {
        margin-top: 0px;
    }
    .checkoutpage h2{
        font-size: 22px;
    }
    .checkoutDetail a h5{
        font-size: 18px;
    }
    .additionalProduct h4, .coupancodeouter h4{
        font-size: 16px;
    }
    .checkoutRight {
        margin: 20px 0px;
        min-height: initial;
        padding-bottom: 10px;
    }
    a.continuebtn{
        font-size: 14px;
    }
    .exclusiveUpdates {
        background-size: 200%;
        background-position: top left;
    }
    .exclusiveUpdates .product-heading p{
        font-size: 18px;
    }
    button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
        left: 4%;
    }
    button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
        right: 4%;
    }
    footer .container{
        flex-direction: column;
    }
    .ourProduct{
        overflow: hidden;
        padding: 50px;
        /* background-image: url(./img/mobilebg.jpg); */
    }
    .ourProduct .col-md-4 {
        margin-bottom: 30px;
    }
    .product-outer::before {
        left: -21px;
        transform: rotate(-51deg);
    }
    .product-outer::after {
        right: -21px;
        transform: rotate(51deg);
    }
    .product-outer a::before {
        left: -21px;
        transform: rotate(51deg);
    }
    .product-outer a::after {
        right: -21px;
        transform: rotate(-51deg);
    }
    header{
        min-height: 70px;
    }
    
    
    a.navbar-brand {
        margin-bottom: 45px;
    }
    
    .daniel-michael {
        position: absolute;
        right: 20px;
        bottom: -11px;
        font-size: 20px;
        font-weight: 700;
        color: #6d2a5f;
        font-style: italic;
    }
    .cartprofile-outer {
        right: 194px;
        top: 0px;
    }
    
    .slider-content{
        width: 320px;
    }
    .sliderinnercontent span.d-block {
        display: inline !important;
    }
    .sliderinnercontent h1 {
        font-size: 36px;
    }
    .slider-content h4 {
        font-size: 24px;
    }
    .slider-content p {
        font-size: 16px;
    }
    
    .product-outer a h3 sup {
        top: -12px;
    }
    .product-outer p{
        font-size: 26px;
    }
    .slider-content h1, .video-headings h2 .innerpages p, .video-headings h2, .product-heading h2, .product-outer a h3, .banner-contentInner h2{
        font-size: 36px;
    }
    .video-headings h3, .product-heading h3, .banner-contentInner h4{
        font-size: 24px;
    }
    .videoTab{
        margin-bottom: 30px;
        width: calc(100% - 70px);
    }
    .video-content h4 {
        font-size: 16px;
        line-height: 20px;
    }
    .video-content img{
        width: 30px;
    }
    .playBtn h5 {
        font-size: 16px;
    }

    .advancements .slider-content .d-flex .btn-primary {
        margin: 0px 0px 10px !important;
    }
    .social-icon ul{
        margin: 10px 0px;
        justify-content: center;
    }
    .advancements .slider-content .d-flex .btn-primary {
        margin: 0px 0px 10px !important;
    }
    
    footer header {
        padding: 30px 0px 0px;
    }
    
    footer header .navbar-collapse {
        top: 0px;
    }
    footer a.navbar-brand {
        margin: 0px;
        position: absolute;
        bottom: 0px;
    }
    .advancements .slider-content .d-flex {
        flex-direction: column;
    }
    .product-heading{
        margin-bottom: 0px;
    }
    .social-icon ul li img {
        min-width: 26px;
        margin: 0px 5px 0px;
    }
    footer header .btn-primary {
        position: absolute;
        bottom: 0px;
        padding: 7px 25px;
        top: 145px;
        left: 0px;
        right: 0px;
        width: 180px;
        margin: 0 auto;
        min-height: 40px;
        line-height: 23px;
    }
    footer header .container {
        padding-bottom: 0px;
        position: relative;
    }
    .mainsection{
        background-image: url(../src/img/top-right.png), url(../src/img/left-bottom.png);
        background-size: 80%;
        background-position: top right, bottom left;
        background-repeat: no-repeat;
        background-color: #e8e5e0;
        padding: 0px 20px;
    }
    footer .navbar-expand-lg .navbar-nav>a, footer .navbar-expand-lg .navbar-nav>li>a, footer .navbar-expand-lg .navbar-nav .dropdown>a{
        font-size: 13px;
    }
    .pageheading .headingContent{
        position: initial;
        padding: 10px 0px 0px;
    }
    /* .productlist-outer{
        background-image: initial;
        background-color: transparent;
    } */
    .headingContent h2{
        font-size: 28px;
        color: #435568;
    }
    .headingContent h4 {
        font-size: 20px;
        width: 80%;
        color: #6d275f;
        margin: 10px 0px;
    }
    .headingContent .btn-primary {font-size: 13px;padding: 7px 32px;background: #6d2a5f !important;color: #fff;}
    .productlist-outer .form-group {
        margin-bottom: 15px;
    }
    .sidebar-outer {
        margin-bottom: 20px;
    }
    .itemList li {
        width: 100%;
    }
    .ItemImgOuter img {
        height: 250px;
    }
    .sidebar::before {
        top: 4px;
        left: -9px;
        transform: rotate(-46deg);
    }
    .sidebar::after {
        top: 4px;
        right: -8px;
        transform: rotate(46deg);
    }
    .mainsection{
        padding: 0px 110px;
    }
    .sidebar h3::before {
        bottom: 4px;
        left: -9px;
        transform: rotate(46deg);
    }
    .sidebar h3::after {
        right: -12px;
        transform: rotate(-46deg);
    }
    .ItemImgOuter img {
        height: 310px;
    }
    .mainOuter::before {
        left: -9px;
        transform: rotate(-56deg);
    }
    .mainOuter .ItemImgOuter::before {
        right: -9px;
        transform: rotate(-57deg);
    }
    .mainOuter .ItemImgOuter::after {
        left: -9px;
        transform: rotate(56deg);
    }
    .mainOuter::after {
        right: -9px;
        transform: rotate(58deg);
    }
    .itemContentOuter {
        padding: 15px 60px;
    }
    .itemContentOuter h4 a {
        font-size: 30px;
        display: block;
    }
    .itemContentOuter p{
        font-size: 21px;
    }
    .itemContentOuter h5 {
        font-size: 36px;
    }
    .itemContentOuter>span {
        font-size: 21px;
    }
    ul.categoryList li:last-child a {
        border-bottom: 0;
    }
    .productDetails .container {
        max-width: 100%;
        padding: 0px;
    }
    
    .productDetails .container .row {
        margin: 0px;
    }
    
    .productDetails .container .row .col-md-6 {
        padding: 0px;
    }
    .productdetails-right {
        padding: 20px 70px;
    }
    .superiordown {
        position: initial;
        width: 100%;
        padding: 0px 50px;
    }
    .superiour-right h1, .productdetails-right h1 a, .paymentoption h5 {
        font-size: 34px;
    }
    .superiour-right h4, .productdetails-right h4 {
        font-size: 30px;
    }
    .alltypes p {
        font-size: 23px;
    }
    .detailheading, .custom_check, .Contact-informations span.custom_check {
        font-size: 16px !important;
    }
    .superiour-right h1 span {
        display: block;
    }
    .paymentoption{
        position: relative;
    }
    
    
    .paymentoption .addcart {
        margin: 0px 0px 0px 15px;
    }
    .detailstabspanel {
        margin-top: 0px;
    }
    .detailstabspanel {
        background: #e7e5df;
        padding: 10px 0px;
    }
    .selectbrand-outer{
        margin-bottom: 15px;
    }
    .mt-5.copare-btn.text-center {
        margin-top: 15px !important;
    }
    
    .bottomtabs .nav-tabs {
        flex-wrap: wrap;
    }
    
    .bottomtabs .nav-tabs li.nav-item button {
        background: #fffef6;
        border-radius: 55px;
        margin: 3px 0px;
        padding: 10px 0px;
    }
    .bottomtabs .nav-tabs {
        flex-wrap: wrap;
    }
    .bottomtabs .nav-tabs li.nav-item {
        width: calc(100% / 2 - 5px);
    }
    .superiour-right h4 span {
        display: block;
    }
    .box-outer{
        background-color: #fffef6;
    }
    .bottomtabs .nav-tabs{
        margin-bottom: 10px;
    }
    .cartlistheader, .cartleftheader {
        flex-direction: column;
    }
    .orderplaced p{
        display: flex;
        justify-content: space-between;
    }
    .cartdetailsright {
        width: 100%;
        margin-top: 30px;
    }
    .cartdetailsright a{
        margin-right: 5px;
    }
    .media-content h4 a{
        font-size: 20px;
    }
    .product-heading.mb-0 {
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
        text-align: center;
    }
    .selector-tools-body{
        flex-direction: column-reverse;
    }
    .select-itemImage, .select-tool-info {
        width: 100%;
    }
    .select-tool-info{
        padding: 30px 0px 0px;
    }
    .small .result-productimages img{
        height: initial;
    }
    .progresh-bg.range-color::after{
        height: 15.5px;
    }
    .detailTBOuter table.w-100.table.table-bordered thead tr th {
        white-space: nowrap;
    }
    .social-icon{
        align-items: flex-start;
    }
    .social-icon ul{
        position: relative;
        top: -3px;
    }
    /* .kinetixx-slider-item .react-multiple-carousel__arrow--left, .kinetixx-slider-item .react-multiple-carousel__arrow--right{
        display: block !important;
    } */
    .kinetixx-product .slick-slider {
        display: none;
    }
    .kinetixx-product .mobile-kinetixx-product {
        width: 290px;
        margin: 0 auto;
        display: block;
    }
    
    

}

@media(max-width: 575.98px){
    #header .btn-primary:hover{
        box-shadow: inset 450px 0px 0px #fffef5;
    }
    .accountInnner p, .backpage div a{
        font-size: 16px;
    }
    .infoheader h3{
        font-size: 18px;
    }
    .stepOne{
        padding: 15px;
    }
    .profilemaintap{
        margin: 0px;
    }
    .iron-content .metricChange .changeMetirc {
        top: 135px;
        left: 54%;
    }
    .table>:not(caption)>*>* {
        padding: 0.4rem 0.3rem;
    }
    
    .emailnews.form-group .form-control{
        padding-right: 90px;
    }
    .detailTBOuter tbody tr th {
        max-width: 136px;
        word-wrap: break-word;
        font-size: 13px;
    }
    .detailTBOuter table.w-100.table.table-bordered tbody tr td{
        font-size: 13px;
    }
    .newsletter-right {
        padding: 110px 20px 20px;
    }
    .labes-type .border-box {padding: 0px 15px;}
    .accountInnner p{
        display: flex;
        align-items: center;
    }
    .compareInner{
        min-width: 60px;
    }
    .itemList{
        margin: 0px -12px;
    }
    .kinetixxproductOuter a h4{
        width: 80%;
    }
    .quantity-tab {
        margin: 0px 5px;
    }
    body {
        padding-top: 115px;
    }
    .productlist-outer, .profileDatatop{
        padding-top: 20px;
    }
    #header a.btn.btn-primary {
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: -47px;
    }
    .cartprofile-outer {
        right: 75px;
    }
    .technology-mat-content .image-gallery-swipe{
        height: 240px;
    }
    .NewsMedia .mediapanel {
        max-width: 100%;
    }
    .mediapanel img {
        height: initial;
        max-height: 300px;
        width: 100%;
    }
    .mediaOuter{
        flex-wrap: wrap;
    }
    .mediapanel, .media-content{
        width: 100%;
    }
    .media-content {
        padding: 20px 0px 0px 0px;
    }
    .inquiries-list ul li {
        width: calc(100% / 1 - 0px);
    }
    .listbody{
        padding: 15px 0px;
        margin-bottom: 0px;
        height: initial;
    }
    .productlist-outer .form-group{
        text-align: center !important;
    }
    .find-store{
        flex-wrap: wrap;
        height: initial;
    }
    .productlist-outer .form-group{
        width: 100%;
    }
    .detailsoforder{
        flex-wrap: wrap;
    }
    .shippingdetails {
        width: 100%;
        margin-bottom: 20px;
    }
    .shippingdetails h5{
        margin-bottom: 4px;
    }
    .checkoutproductdetails.check-out-details-panel{
        flex-wrap: wrap;
    }
    .checkoutproductdetails.check-out-details-panel .check-out-panel-left, .checkout-review{
        width: 100%;
    }
    .checkout-review .checkoutRight.yourreviewright{
        border-top: 1px solid #ccc;
        border-left: 0px;
    }
    .orderdetailsheader ul li{
        padding-right: 5px;
        margin-right: 5px;
        font-size: 13px;
    }
    ul.profilelist{
        width: 230px;
        left: initial;
        right: -60px;
    }
    .checkoutproductdetails .checkoutDouter {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
    .submenu-outer ul.submenuList>li>a{
        margin: 0px;
        padding: 2px 5px !important;
    }
    .submenuList>li{
        width: 100%;
    }
    .cartheader{
        flex-direction: column;
    }
    .cartheader .searchProduct {
        margin: 9px 0px 0px 0px;
        width: 100%;
    }
    .orderplaced{
        margin: 0px;
    }
    .list-unstyled.multi-steps li::before{
        display: none;
    }
    .list-unstyled.multi-steps li svg {
        left: initial;
    }
    .list-unstyled.multi-steps li {
        font-size: 10px;
    }
    .cartproductcontentdetails a h6{
        font-size: 18px;
    }
    
    ul.sortmenu {
        display: none;
    }
    .navbar-expand-lg .navbar-nav li:hover ul.sortmenu{
        display: block;
        position: initial;
    }
    .accountseprate {
        width: calc(100% / 1);
    }
    .store-banner .headingContent {
        text-align: center;
        margin: 20px 0px;
    }
    .store-banner img {
        width: 170px;
    }
    .informations .Contact-informations {margin: 0px 0px 15px;}
    a.returnshopping {
        margin-bottom: 12px;
    }
    .d-flex.justify-content-between.align-items-center.mt-3.mb-3 {
        flex-wrap: wrap;
    }
    .headingContent h2 {
        font-size: 24px;
    }
    .ItemImgOuter img {
        height: 220px;
        width: 100%;
        object-fit: cover;
    }
    .sidebar::before {
        top: 9px;
        left: -14px;
        transform: rotate(-53deg);
    }
    .sidebar::after {
        top: 4px;
        right: -10px;
        transform: rotate(55deg);
    }
    .sidebar h3::before {
        bottom: 10px;
        left: -15px;
        transform: rotate(53deg);
    }
    .sidebar h3::after {
        right: -13px;
        transform: rotate(-54deg);
    }
    .mainOuter .ItemImgOuter::before {
        right: -11px;
        transform: rotate(-59deg);
    }
    .mainOuter .ItemImgOuter::after {
        left: -11px;
        transform: rotate(58deg);
    }
    .mainOuter::after {
        right: -11px;
        transform: rotate(62deg);
    }
    .mainOuter::before {
        left: -11px;
        transform: rotate(-61deg);
    }
    .itemContentOuter {
        padding: 15px 10px;
    }
    section.video-outer{
        padding: 30px 15px;
    }
    .video-content{
        padding: 10px;
    }
    .product-selector .product-heading {
        padding: 0px 45px;
    }
    .product-selector p {
        padding: 0px 20px;
    }
    .videos-outer iframe {
        width: 100%;
        min-height: 100%;
        height: 260px;
    }
    .navbar-brand img {
        width: 156px;
    }
    header {
        min-height: 120px;
    }
    .daniel-michael{
        font-size: 13px;
    }
    header .btn-primary {
        font-size: 13px;
        padding: 5px 12px;
    }
    .navbar-collapse {
        top: 95px;
    }
    .product-outer a h3 span.d-block{
        font-size: 28px;
    }
    .technology-mat-content .image-gallery{
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
        float: initial;
    }
    .slider-content h4, .banner-contentInner h4 {
        font-size: 18px;
    }
    .slider-content h1, .technology-mat-content h2, .video-headings h2 .innerpages p, .other-match h4, .media-content h4 a, .video-headings h2, .product-heading h2, .product-outer a h3, .banner-contentInner h2 {
        font-size: 26px;
    }
    .slider-content {
        width: 260px;
    }
    .slider-content p {
        font-size: 13px;
        line-height: 18px;
    }
    .slider-content .d-flex {
        margin: 0px;
    }
    .slider-content .btn-primary, .back-next .btn-primary, .back-next a {
        font-size: 12px;
        padding: 7px 10px;
    }
    .mobileview {
        height: 420px;
        object-fit: cover;
        object-position: -17px;
    }
    .ourProduct{
        padding: 30px 20px;
        background-size: 80%;
    }
    .product-outer::after {
        right: -21px;
        transform: rotate(51deg);
    }
    .product-outer::before {
        left: -22px;
        transform: rotate(-51deg);
    }
    .product-outer p {
        font-size: 20px;
    }
    .product-outer a h3 img, .product-heading img {
        height: 35px;
        width: auto;
    }
    .product-outer a::after {
        right: -21px;
        transform: rotate(-50deg);
    }
    .product-outer a::before {
        left: -22px;
        transform: rotate(50deg);
    }
    .video-headings h3, .product-heading h3 {
        font-size: 18px;
    }
    .advancements .slider-content h3 {
        margin: 3px 0px;
        font-size: 18px;
    }
    .shaftContent .dark.btn-primary {
        padding: 10px 25px;
        font-size: 16px;
    }
    
    .exclusiveUpdates, .product-selector, .video-outer {
        padding: 30px 0px;
    }
    .exclusiveUpdates .btn-primary{
        font-size: 16px;
        padding: 15px 35px;
    }
    footer header .navbar-expand-lg .navbar-nav .nav-link {
        padding: 10px 2px !important;
        font-size: 11px;
    }
    footer header {
        padding: 10px 0px 0px;
    }
    footer p{
        font-size: 14px;
    }
    footer p sup{
        width: 12px;
        height: 12px;
    }
    .video-content h4{
        font-size: 15px;
    }
    .video-outer .react-multiple-carousel__arrow{
        top: 27%;
    }
    .mainsection {
        padding: 0px;
    }
    .superiour-right h4 {
        font-size: 22px;
    }
    .productdetails-right {
        padding: 20px 15px;
    }
    .superiour-right h1, .productdetails-right h1 a, .paymentoption h5 {
        font-size: 22px;
    }
    .superiour-right h4, .productdetails-right h4, .detailheading, .custom_check, .Quantitybox h5 {
        font-size: 22px;
    }
    .alltypes p {
        font-size: 18px;
    }
    .favrateicon svg {
        font-size: 40px;
    }
    .selectproductcolor .btn label, .selectproductflex .btn.block label{
        padding: 8px 20px;
    }
    .superiour-left {
        min-width: 100px;
        max-width: 100px;
    }
    .superiordown{
        padding: 0px 20px;
    }
    .notify .btn-primary {
        padding: 10px 35px;
    }
    
    .paymentoption .addcart {
        margin: 0px;
    }
    a.removeproduct {
        position: initial;
        text-align: right;
        padding: 0px 5px 0px 0px;
    }
    
    .checkoutDouter {
        flex-direction: column;
    }
    .totals {
        align-items: flex-start;
        padding-left: 78px;
    }
    .checkimg, .addproductimg{
        width: 60px;
        min-height: 60px;
    }
    .addcontent{
        width: calc(100% - 60px);
        padding-right: 60px;
    }
    .quantity-tab .btn{
        padding: 5px 6px;
    }
    .compare-btn-outer .btn {
        margin-bottom: 10px;
        min-width: auto !important;
        font-size: 12px;
    }
    
}

@media(max-width: 400px){
    .paymentoption .btn-primary{
        min-width: auto;
    }
    .paymentoption .text-center{
        padding-left: 21px;
    }
    .product-outer::after {
        right: -25px;
        transform: rotate(56deg);
    }
    .product-outer::before {
        left: -25px;
        transform: rotate(-55deg);
    }
    .product-outer a::after {
        right: -25px;
        transform: rotate(-57deg);
    }
    .product-outer a::before {
        left: -25px;
        transform: rotate(57deg);
    }
    .videos-outer iframe{
        height: 140px;
    }
}
@media(min-width: 993px){
	.navbar-expand-lg .navbar-nav .dropdown-menu {
	    margin: 0;
	    max-height: calc(100vh - 111px);
	    overflow: auto;
	}
}