.spec-head {
    background: #e7e5df;
    min-height: 81px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* .custom_carousel {
    height: 600px;
    width: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.custom_carousel_video {
    width: 100%;
    height: 100%;
}

.slide div {
    visibility: visible !important;
}

.arrow-btn {
    height: 80px;
    border: none;
    background: none;
} */

/* .custom_carousel_image {
    width: 100%;
} */

.custom_carousel {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 30px auto;
    display: flex;
    justify-content: center;
}

.slide {
    position: absolute;
    width: 100%;
    height: 450px;
}

.arrow-btn {
    position: absolute;
    top: 50%;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    z-index: 1;
}

.arrow-btn:hover {
    color: #6d2a5f;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.arrow-btn.prev {
    left: 10px;
}

.arrow-btn.next {
    right: 10px;
}

.image-wrapper {
    width: 100%;
    height: auto;
    margin-top: 40px;
    display: flex;
    justify-content: center;

}

.video-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.custom-carousel-video {
    width: 100%;
}

.preview-images {
    position: absolute;
    bottom: 10px;
    left: 0;
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    z-index: 1;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: auto;
}

.preview-image {
    width: 100px;
    height: 60px;
    margin: 0px 5px 0px 0px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s ease;
    border: 4px solid transparent;
    flex-shrink: 0;
}

.preview-image.active {
    opacity: 1;
    border-color: #337ab7;
}