/* input {
    padding: 1em;
    margin: 0.5em 0;
    display: block;
} */

/* .input-group {
    width: 60%;
    display: inline-block;
    position: relative;
} */
/* 
input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid gray;
} */

label {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
}

.card-number svg {
    position: absolute;
    right: 10px;
    bottom: 30%;
}


.multi-input {
    margin-top: 20px;
    width: 60%;
    display: flex;
    gap: 10px;
}

small {
    color: red;
    font-size: 10px;
    display: block;
}

.form-check label.form-check-label,
.list-group-item .form-check label.form-check-label {
    position: relative;
    top: 5px;
    left: 11px;
}

.form-check input#custom-switch,
.list-group-item .form-check input.form-check-input {
    padding: 12px;
}